
import { Component, Vue } from 'vue-property-decorator'
import { RequisitiongList } from '@/types/assetScheduling'

@Component({
  name: 'RequisitionList'
})
export default class Requisition extends Vue {
  private tableData: RequisitiongList[] =[]
  private info= {
    orderNumber: '',
    startDate: '',
    endDate: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private dataRange = []
  created () {
    this.getData()
  }

  getData () {
    if (this.dataRange) {
      this.info.startDate = this.dataRange[0]
      this.info.endDate = this.dataRange[1]
    } else {
      this.info.startDate = ''
      this.info.endDate = ''
    }
    this.loading = true
    this.$axios.get(this.$apis.assetScheduling.selectAssetReceiveByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onAdd () {
    this.$router.push({ path: '/requisitionRefund/requisition/add' })
  }

  onDetail (orderId: string) {
    this.$router.push({ name: 'requisitionDetail', params: { orderId } })
  }

  // 导出
  onExport (id: string) {
    const loading = this.$loading({
      lock: true,
      text: '导出中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.assetScheduling.exportAssetReceive, { orderId: id }).finally(() => {
      loading.close()
    })
  }
}
